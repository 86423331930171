import classNames from "classnames";
import { useScroll } from "framer-motion";
import React, { useEffect, useState } from "react";
import {
  SanityLandingPageString,
  findContent
} from "../../../model/landing-page";
import { Content } from "./AnimatedSection";
import styles from "./AnimatedSection.module.scss";
import AnimationDescription from "./AnimationDescription";
import FeatureList from "./FeatureList";

const FeatureListSection = React.forwardRef(
  (
    {
      content,
      mobileAnimation
    }: { content: Content; mobileAnimation: boolean },
    ref: React.Ref<HTMLDivElement>
  ) => {
    const [featuresLeftHeading] = findContent<SanityLandingPageString>(
      "featuresLeftHeading",
      content
    );
    const featuresLeftItems = findContent<SanityLandingPageString>(
      "featuresLeftItems",
      content
    );
    const [featuresRightHeading] = findContent<SanityLandingPageString>(
      "featuresRightHeading",
      content
    );
    const featuresRightItems = findContent<SanityLandingPageString>(
      "featuresRightItems",
      content
    );
    const [animationDescription] = findContent<SanityLandingPageString>(
      "featuresAlt",
      content
    );
    const animationDescriptionId = "featuresAnimationDescription";
    const { scrollYProgress } = useScroll({
      target: ref as React.RefObject<HTMLDivElement>,
      offset: ["start end", "end center"]
    });
    const [showAnimationDescription, setShowAnimationDescription] = useState(
      false
    );
    useEffect(() => {
      scrollYProgress.onChange(value => {
        if (value === 0 || value === 1) {
          setShowAnimationDescription(false);
        } else {
          setShowAnimationDescription(true);
        }
      });
      return () => scrollYProgress.clearListeners();
    }, [scrollYProgress]);
    return (
      <section
        className={classNames(
          styles.animatedSection,
          styles.featureListSection
        )}
        ref={ref}
      >
        <div className={classNames(styles.content, styles.absolutePosition)}>
          <AnimationDescription
            id={animationDescriptionId}
            text={animationDescription.value}
            show={showAnimationDescription}
          />
          <div
            className={styles.restrictedContent}
            aria-describedby={animationDescriptionId}
          >
            <FeatureList
              mobileAnimation={mobileAnimation}
              heading={featuresLeftHeading.value}
              listItemText={featuresLeftItems.map(c => c.value)}
              sensors
            />
            <FeatureList
              mobileAnimation={mobileAnimation}
              heading={featuresRightHeading.value}
              listItemText={featuresRightItems.map(c => c.value)}
            />
          </div>
        </div>
      </section>
    );
  }
);

export default FeatureListSection;
