import React from "react";
import AspectRatio from "../../components/AspectRatio/AspectRatio";
import CallToActionButton from "../../components/CallToActionButton/CallToActionButton";
import { SanityCallToAction, SanityImage } from "../../model/common";
import { imageUrlBuilder } from "../../utils/images";
import {
  SanityLandingPageCustomBlock,
  findContent
} from "../../model/landing-page";
import styles from "./Hero.module.scss";
import SectionWrapper from "./SectionWrapper";
import Title from "./Title";
import downArrow from "./assets/rounded-green-arrow.svg";

const Hero = ({
  node: { title, subtitle, content }
}: {
  node: SanityLandingPageCustomBlock;
}) => {
  const [image] = findContent<SanityImage>("image", content);
  const ctas = findContent<SanityCallToAction>("ctas", content);
  const buttons = ctas.map(cta => (
    <CallToActionButton
      key={cta._key}
      node={{
        title: cta.title,
        externalLink: cta.externalLink?.href,
        internalLink: cta.internalLink?.reference,
        fragment: cta.internalLink?.fragment,
        primary: false
      }}
    />
  ));
  return (
    <SectionWrapper className={styles.root}>
      <AspectRatio ratio={1920 / 1245} className={styles.container}>
        <div>
          <div className={styles.text}>
            <Title as="h1" variant="main">
              {title}
            </Title>
            <p className={styles.desktop}>{subtitle}</p>
          </div>
          <div className={styles.desktop}>
            <div className={styles.buttons}>{buttons}</div>
          </div>
          <img
            className={styles.heroImage}
            src={
              imageUrlBuilder
                .image(image.asset)
                .quality(95)
                .url()!
            }
            alt={image.alt}
          />
          <img src={downArrow} alt="" className={styles.downArrow} />
        </div>
      </AspectRatio>
      <div className={styles.mobile}>
        <div className={styles.text}>
          <p>{subtitle}</p>
        </div>
        <div className={styles.buttons}>{buttons}</div>
      </div>
    </SectionWrapper>
  );
};

export default Hero;
