import classNames from "classnames";
import {
  motion,
  useReducedMotion,
  useScroll,
  useTransform
} from "framer-motion";
import React, { useEffect, useState } from "react";
import { SanityImage } from "../../../model/common";
import { imageUrlBuilder } from "../../../utils/images";
import {
  SanityLandingPageString,
  findContent
} from "../../../model/landing-page";
import { AnimatedSectionProps } from "./AnimatedSection";
import styles from "./AnimatedSection.module.scss";
import trainerShadow from "./assets/shadow.svg";
import stompMarks from "./assets/stomp-marks.svg";
import AnimationDescription from "./AnimationDescription";
import Text from "../../../components/Text/Text";
import VisuallyHidden from "../../../components/VisuallyHidden/VisuallyHidden";

const StepIntoSection = React.forwardRef(
  (
    { onChangeMicrobitImage, scrollYProgress, content }: AnimatedSectionProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const [showAnimationDescription, setShowAnimationDescription] = useState(
      false
    );

    useEffect(() => {
      scrollYProgress.onChange(value => {
        if (value === 0 || value === 1) {
          setShowAnimationDescription(false);
        } else {
          setShowAnimationDescription(true);
        }

        if (value < 0.4) {
          onChangeMicrobitImage("zero");
        } else if (value < 0.8) {
          onChangeMicrobitImage("one");
        } else if (value >= 0.8) {
          onChangeMicrobitImage("two");
        }
      });
      return () => scrollYProgress.clearListeners();
    }, [onChangeMicrobitImage, scrollYProgress]);

    const { scrollYProgress: textScrollYProgress } = useScroll({
      target: ref as React.RefObject<HTMLDivElement>,
      offset: ["start end", "start start"],
      axis: "y"
    });

    const [trainer] = findContent<SanityImage>("trainerImage", content);
    const [headingPt1, headingPt2] = findContent<SanityLandingPageString>(
      "stepIntoTheRealWorldHeading",
      content
    );
    const [animationDescription] = findContent<SanityLandingPageString>(
      "stepIntoTheRealWorldAlt",
      content
    );

    const shouldReduceMotion = useReducedMotion();
    const headingPt1Animation = useTransform(
      textScrollYProgress,
      [0, 1],
      ["-33%", "0%"]
    );
    const headingPt2Animation = useTransform(
      textScrollYProgress,
      [0, 1],
      ["33%", "0%"]
    );
    const animationDescriptionId = "stepIntoTheRealWorldAnimationDescription";
    return (
      <section
        className={classNames(styles.animatedSection, styles.stepIntoSection)}
        ref={ref}
      >
        <div
          className={classNames(
            styles.fixedPosition,
            styles.flexCenter,
            styles.flexColumn,
            styles.stickyPosition
          )}
          style={{
            gap: "2rem"
          }}
        >
          <VisuallyHidden>
            <h2>{`${headingPt1.value} ${headingPt2.value}`}</h2>
          </VisuallyHidden>
          <AnimationDescription
            id={animationDescriptionId}
            text={animationDescription.value}
            show={showAnimationDescription}
          />
          <div
            className={classNames(styles.trainerContainer, styles.flexColumn)}
            role="img"
            aria-describedby={animationDescriptionId}
          >
            <motion.img
              className={styles.trainer}
              src={imageUrlBuilder.image(trainer).url()!}
              alt=""
              style={{
                y: useTransform(
                  scrollYProgress,
                  [0.2, 0.37, 0.4, 0.6, 0.77, 0.8],
                  ["0vh", "-10vh", "0vh", "0vh", "-10vh", "0vh"]
                )
              }}
            />

            <motion.img
              className={styles.trainerShadow}
              src={trainerShadow}
              alt=""
              style={{
                scale: useTransform(
                  scrollYProgress,
                  [0.2, 0.37, 0.4, 0.6, 0.77, 0.8],
                  [1, 0.8, 1, 1, 0.8, 1]
                ),
                y: "40%"
              }}
            />
            <motion.img
              className={styles.stompMarks}
              src={stompMarks}
              alt=""
              style={{
                opacity: useTransform(
                  scrollYProgress,
                  [0.4, 0.401, 0.5, 0.501, 0.8, 0.801, 0.899, 0.9],
                  [0, 1, 1, 0, 0, 1, 1, 0]
                )
              }}
            />
          </div>
          <div className={styles.content}>
            <div className={styles.restrictedContent} aria-hidden>
              <motion.div
                style={{
                  x: shouldReduceMotion ? 0 : headingPt1Animation
                }}
                className={styles.darkCopy}
              >
                <Text variant="h1" as="h2">
                  {headingPt1.value}
                </Text>
              </motion.div>
              <motion.div
                style={{
                  x: shouldReduceMotion ? 0 : headingPt2Animation
                }}
                className={styles.darkCopy}
              >
                <Text variant="h3" as="h2">
                  {headingPt2.value}
                </Text>
              </motion.div>
            </div>
          </div>
        </div>
      </section>
    );
  }
);

export default StepIntoSection;
