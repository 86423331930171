import React from "react";
import styles from "./TabCutout.module.scss";
import classNames from "classnames";

interface TabCutoutProps {
  color: string;
  positionRight?: boolean;
}

const TabCutout = ({ color, positionRight = false }: TabCutoutProps) => (
  <svg
    className={classNames(styles.root, positionRight && styles.positionRight)}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100.43 26.11"
  >
    <path
      fill={color}
      d="M 6.82,22.29 18.14,3.82 C 19.59,1.45 22.18,0 24.96,0 h 50.51 c 2.78,0 5.37,1.45 6.82,3.82 l 11.32,18.47 c 1.45,2.37 4.04,3.82 6.82,3.82 H 0 c 2.78,0 5.37,-1.45 6.82,-3.82 z"
    />
  </svg>
);

export default TabCutout;
