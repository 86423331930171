import classNames from "classnames";
import { motion, useScroll, useTransform } from "framer-motion";
import React, { useRef } from "react";
import styles from "./AnimatedSection.module.scss";
import FeatureListItem from "./FeatureListItem";
import Text from "../../../components/Text/Text";

interface FeatureListProps {
  heading: string;
  listItemText: string[];
  sensors?: boolean;
  mobileAnimation: boolean;
}

const FeatureList = ({
  heading,
  listItemText,
  sensors = false,
  mobileAnimation
}: FeatureListProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "start start"],
    axis: "y"
  });
  return (
    <div
      className={classNames(
        styles.restrictedContentChild,
        !sensors && styles.rightAlign
      )}
    >
      <motion.div
        ref={ref}
        style={{
          opacity: useTransform(
            scrollYProgress,
            [0.4, 0.45, 0.5, 0.55, 0.8, 0.9],
            [0.2, 0.7, 1, 0.7, 0.7, 0.2]
          )
        }}
      >
        <Text variant="h2" as="h3">
          {heading}
        </Text>
      </motion.div>
      <ul className={styles.parallaxList}>
        {listItemText.map((text, i) => (
          <FeatureListItem
            key={mobileAnimation + text}
            sensor={sensors}
            index={i}
            text={text}
          />
        ))}
      </ul>
    </div>
  );
};

export default FeatureList;
