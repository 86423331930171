import React from "react";
import ArticleCard from "../../components/ArticleCard/ArticleCard";
import GenericCard from "../../components/GenericCard/GenericCard";
import { SanityCallToAction, SanityCardList } from "../../model/common";
import {
  findContent,
  SanityLandingPageCustomBlock
} from "../../model/landing-page";

import styles from "./OnlineCodingTools.module.scss";
import SectionWrapper from "./SectionWrapper";
import Title from "./Title";
import Subtitle from "./Subtitle";
import CallToActionButton from "../../components/CallToActionButton/CallToActionButton";

const OnlineCodingTools = ({
  node: { title, subtitle, content }
}: {
  node: SanityLandingPageCustomBlock;
}) => {
  const [cardList] = findContent<SanityCardList>("cards", content);
  const [cta] = findContent<SanityCallToAction>("cta", content);
  return (
    <SectionWrapper className={styles.root}>
      <Title as="h2" variant="small">
        {title}
      </Title>
      <Subtitle>{subtitle}</Subtitle>
      <div className={styles.cards}>
        {cardList.items.map(item => {
          if (item._type === "cardInternalLink") {
            return <ArticleCard key={item._key} article={item.reference} />;
          }
          return (
            <GenericCard
              key={item._key}
              layout="stretch"
              card={item}
              readMore={false}
            />
          );
        })}
      </div>
      <div className={styles.cta}>
        <CallToActionButton
          node={{
            title: cta.title,
            externalLink: cta.externalLink?.href,
            internalLink: cta.internalLink?.reference,
            fragment: cta.internalLink?.fragment,
            primary: false
          }}
        />
      </div>
    </SectionWrapper>
  );
};

export default OnlineCodingTools;
