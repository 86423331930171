import classNames from "classnames";
import { motion, useScroll, useTransform } from "framer-motion";
import React, { useEffect, useState } from "react";
import { SanityImage } from "../../../model/common";
import { imageUrlBuilder } from "../../../utils/images";
import {
  SanityLandingPageString,
  findContent
} from "../../../model/landing-page";
import { AnimatedSectionProps } from "./AnimatedSection";
import styles from "./AnimatedSection.module.scss";
import computer from "./assets/computer.svg";
import AnimationDescription from "./AnimationDescription";
import Text from "../../../components/Text/Text";

const ChangeCodeSection = React.forwardRef(
  (
    { onChangeMicrobitImage, scrollYProgress, content }: AnimatedSectionProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const [showAnimationDescription, setShowAnimationDescription] = useState(
      false
    );
    const [computerOpacity, setComputerOpacity] = useState(0);
    const { scrollYProgress: computerOpacityScrollProgress } = useScroll({
      target: ref as React.RefObject<HTMLDivElement>,
      offset: ["start -200px", "end end"]
    });

    useEffect(() => {
      computerOpacityScrollProgress.onChange(value => {
        if (value > 0) {
          setComputerOpacity(1);
        } else {
          setComputerOpacity(0);
        }
      });
      return () => computerOpacityScrollProgress.clearListeners();
    }, [computerOpacityScrollProgress]);

    useEffect(() => {
      scrollYProgress.onChange(value => {
        if (value === 0 || value === 1) {
          setShowAnimationDescription(false);
        } else {
          setShowAnimationDescription(true);
        }

        if (value === 0) {
          onChangeMicrobitImage("flashingHeart");
        } else if (value > 0 && value < 0.9) {
          onChangeMicrobitImage("off");
        } else if (value >= 0.9 && value < 1) {
          onChangeMicrobitImage("zero");
        }
      });
      return () => scrollYProgress.clearListeners();
    }, [onChangeMicrobitImage, scrollYProgress]);

    const [codeShakeImage] = findContent<SanityImage>(
      "stepCounterCodeImage",
      content
    );
    const [headingPt1, headingPt2] = findContent<SanityLandingPageString>(
      "changeCodeHeading",
      content
    );
    const [animationDescription] = findContent<SanityLandingPageString>(
      "changeCodeAlt",
      content
    );

    const [initialPosition, setInitialPosition] = useState(
      typeof window !== "undefined"
        ? window.innerWidth < 768
          ? "15%"
          : "50%"
        : "50%"
    );
    useEffect(() => {
      const listener = (e: UIEvent) => {
        if (((e.target as Window).innerWidth || 0) < 768) {
          setInitialPosition("15%");
        } else {
          setInitialPosition("50%");
        }
      };
      if (typeof window !== "undefined") {
        window.addEventListener("resize", listener);
      }
      return () => window.removeEventListener("resize", listener);
    }, []);
    const animationDescriptionId = "changeCodeAnimationDescription";
    return (
      <section
        className={classNames(styles.animatedSection, styles.changeCodeSection)}
        ref={ref}
      >
        <div
          className={classNames(
            styles.fixedPosition,
            styles.flexCenter,
            styles.responsiveColumn,
            styles.stickyPosition
          )}
        >
          <div className={styles.restrictedContent}>
            <div className={styles.flexCenter} style={{ height: "100%" }}>
              <AnimationDescription
                id={animationDescriptionId}
                text={animationDescription.value}
                show={showAnimationDescription}
              />
              <div
                className={classNames(
                  styles.restrictedContentChild,
                  styles.codeAnimationContainer
                )}
                role="img"
                aria-describedby={animationDescriptionId}
              >
                <img
                  className={styles.computerSVG}
                  src={computer}
                  alt=""
                  style={{
                    opacity: computerOpacity
                  }}
                />
                <motion.img
                  className={classNames(styles.codeBlock, styles.codeShake)}
                  src={imageUrlBuilder.image(codeShakeImage).url()!}
                  alt=""
                  style={{
                    position: "absolute",
                    left: useTransform(
                      scrollYProgress,
                      [0.5, 0.9],
                      [initialPosition, "75%"]
                    ),
                    top: useTransform(
                      scrollYProgress,
                      [0.1, 0.4],
                      ["100%", "50%"]
                    ),
                    opacity: useTransform(
                      scrollYProgress,
                      [0.1, 0.4, 0.7, 0.9],
                      [0, 1, 1, 0]
                    )
                  }}
                />
              </div>
              <div className={styles.horizontalSpacer} />
              <div
                className={classNames(
                  styles.restrictedContentChild,
                  styles.rightAlign
                )}
              >
                <Text
                  className={classNames(styles.heading, styles.desktopOnly)}
                  variant="h1"
                  as="h3"
                >
                  {headingPt1.value}
                </Text>
                <Text variant="h2" as="p" className={styles.desktopOnly}>
                  {headingPt2.value}
                </Text>
              </div>
            </div>
          </div>
          <div
            className={classNames(
              styles.responsiveOnly,
              styles.responsiveCodeText
            )}
          >
            <Text className={styles.heading} variant="h1" as="h3">
              {headingPt1.value}
            </Text>
            <Text variant="h2" as="p">
              {headingPt2.value}
            </Text>
          </div>
        </div>
      </section>
    );
  }
);

export default ChangeCodeSection;
