import React from "react";
import BlockContent from "../../components/BlockContent/BlockContent";
import { SanityStyledContent } from "../../components/StyledContent/StyledContent";
import {
  findContent,
  SanityLandingPageCustomBlock
} from "../../model/landing-page";
import styles from "./FAQ.module.scss";
import SectionWrapper from "./SectionWrapper";
import { SanityCallToAction } from "../../model/common";
import { Link } from "gatsby";
import Title from "./Title";
import Card from "../../components/Card/Card";
import Text from "../../components/Text/Text";

const FAQ = ({
  node: { title, content }
}: {
  node: SanityLandingPageCustomBlock;
}) => {
  const [faq] = findContent<SanityStyledContent>("content", content);
  const [cta] = findContent<SanityCallToAction>("cta", content);
  return (
    <SectionWrapper className={styles.root}>
      <Card type="main" className={styles.content}>
        <div className={styles.title}>
          <Title as="h2" variant="small">
            {title}
          </Title>
          <Link to={cta.externalLink?.href!}>
            <Text variant="default">{cta.title}</Text>
          </Link>
        </div>
        <BlockContent content={faq.content} />
      </Card>
    </SectionWrapper>
  );
};

export default FAQ;
