import classNames from "classnames";
import {
  motion,
  useReducedMotion,
  useScroll,
  useTransform
} from "framer-motion";
import React, { useRef } from "react";
import {
  findContent,
  SanityLandingPageString
} from "../../../model/landing-page";
import { Content } from "./AnimatedSection";
import styles from "./AnimatedSection.module.scss";
import Text from "../../../components/Text/Text";
import VisuallyHidden from "../../../components/VisuallyHidden/VisuallyHidden";

const AnimatedTitle = ({ content }: { content: Content }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "end start"],
    axis: "y"
  });
  const [titlePt1, titlePt2] = findContent<SanityLandingPageString>(
    "title",
    content
  );
  const shouldReduceMotion = useReducedMotion();
  const titlePt1Animation = useTransform(
    scrollYProgress,
    [0, 1],
    ["33%", "-15%"]
  );
  const titlePt2Animation = useTransform(
    scrollYProgress,
    [0, 1],
    ["-33%", "15%"]
  );
  return (
    <div className={styles.aboveMicrobit}>
      <VisuallyHidden>
        <h2>{`${titlePt1.value} ${titlePt2.value}`}</h2>
      </VisuallyHidden>
      <div
        ref={ref}
        className={classNames(styles.content, styles.paddingVertical)}
        aria-hidden
      >
        <motion.div
          style={{
            x: shouldReduceMotion ? 0 : titlePt1Animation
          }}
        >
          <Text variant="h1" as="h2">
            {titlePt1.value}
          </Text>
        </motion.div>
        <motion.div
          style={{
            x: shouldReduceMotion ? 0 : titlePt2Animation
          }}
        >
          <Text variant="h1" as="h2">
            {titlePt2.value}
          </Text>
        </motion.div>
      </div>
    </div>
  );
};

export default AnimatedTitle;
