import React from "react";
import BlockQuote from "../../components/BlockQuote/BlockQuote";
import { SanityBlockQuote } from "../../model/common";
import {
  SanityLandingPageCustomBlock,
  findContent
} from "../../model/landing-page";
import SectionWrapper from "./SectionWrapper";
import styles from "./EasyEffectiveEngaging.module.scss";
import Title from "./Title";
import squiggle from "./assets/blue-squiggle.svg";
import smile from "./assets/microbit-face-smile.svg";
import cross from "./assets/cross.svg";

const EasyEffectiveEngaging = ({
  node: { title, content }
}: {
  node: SanityLandingPageCustomBlock;
}) => {
  const [quote] = findContent<SanityBlockQuote>("quote", content);
  return (
    <SectionWrapper className={styles.root}>
      <Title as="h2" maxWidth="1000px">
        {title}
      </Title>
      <div className={styles.quote}>
        <BlockQuote node={quote} variant="landing" />
        <img src={smile} alt="" className={styles.smile} />
        <img src={squiggle} alt="" className={styles.squiggle} />
        <img src={cross} alt="" className={styles.cross} />
      </div>
    </SectionWrapper>
  );
};

export default EasyEffectiveEngaging;
