import classNames from "classnames";
import {
  motion,
  useReducedMotion,
  useScroll,
  useTransform
} from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import styles from "./AnimatedSection.module.scss";
import Text from "../../../components/Text/Text";

interface FeatureListItemProps {
  text: string;
  index: number;
  sensor: boolean;
}

const FeatureListItem = ({ text, index, sensor }: FeatureListItemProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "end start"],
    axis: "y"
  });

  const opacity = useTransform(
    scrollYProgress,
    [0.4, 0.45, 0.5, 0.55, 0.6],
    [0.2, 0.7, 1, 0.7, 0.2]
  );

  const [display, setDisplay] = useState<"none" | "block">("none");

  // Required so we don't block nav buttons.
  useEffect(() => {
    scrollYProgress.onChange(value => {
      if (value > 0.4 && value < 0.6) {
        setDisplay("block");
      } else {
        setDisplay("none");
      }
    });
    return () => scrollYProgress.clearListeners();
  }, [scrollYProgress]);

  const shouldReduceMotion = useReducedMotion();
  return (
    <motion.li
      style={{
        opacity,
        marginTop: "3.5rem"
      }}
    >
      <div ref={ref}>
        <Text className={styles.heading} variant="h1" as="p">
          {text}
        </Text>
      </div>
      <div
        className={classNames(
          styles.stickyIconsHighlight,
          styles[`stickyIcons${sensor ? "Sensor" : ""}${index}`]
        )}
        style={{
          display: shouldReduceMotion ? "none" : display
        }}
      />
    </motion.li>
  );
};

export default FeatureListItem;
