import classNames from "classnames";
import {
  motion,
  MotionValue,
  useReducedMotion,
  useScroll,
  useTransform
} from "framer-motion";
import React, {
  ForwardedRef,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import { Helmet } from "react-helmet";
import {
  CustomBlockCompontent,
  SanityLandingPageCustomBlock
} from "../../../model/landing-page";
import SectionWrapper from "../SectionWrapper";
import AddCodeSection from "./AddCodeSection";
import styles from "./AnimatedSection.module.scss";
import AnimatedTitle from "./AnimatedTitle";
import microbitZero from "./assets/microbit-0-leds.png";
import microbitOne from "./assets/microbit-1-leds.png";
import microbitTwo from "./assets/microbit-2-leds.png";
import microbitHeart from "./assets/microbit-heart-leds.png";
import microbitHeartSmall from "./assets/microbit-heart-small-leds.png";
import microbitSmile from "./assets/microbit-smile-leds.png";
import microbit from "./assets/microbit.png";
import ChangeCodeSection from "./ChangeCodeSection";
import FeatureListSection from "./FeatureListSection";
import ProjectsSection from "./ProjectsSection";
import StepIntoSection from "./StepIntoSection";

type MicrobitImage = "smile" | "zero" | "one" | "two" | "off" | "flashingHeart";

export type Content = {
  type: string;
  content: CustomBlockCompontent[];
}[];

export interface AnimatedSectionProps {
  onChangeMicrobitImage: (choice: MicrobitImage) => void;
  scrollYProgress: MotionValue<number>;
  endScrollYProgress: MotionValue<number>;
  content: Content;
}

const AnimatedSection = ({
  node: { content }
}: {
  node: SanityLandingPageCustomBlock;
}) => {
  const [microbitImage, setMicrobitImage] = useState<string>(microbitSmile);
  let timeoutRef = useRef<ReturnType<typeof setTimeout> | undefined>();
  let previousChoice = useRef("");

  const handleMicrobitImage = useCallback((choice: MicrobitImage) => {
    if (previousChoice.current === choice) {
      return;
    }
    previousChoice.current = choice;
    clearTimeout(timeoutRef.current);
    timeoutRef.current = undefined;
    switch (choice) {
      case "smile":
        setMicrobitImage(microbitSmile);
        break;
      case "off":
        setMicrobitImage("");
        break;
      case "flashingHeart":
        const swapImage = () => {
          setMicrobitImage(image => {
            const nextImage =
              image === microbitHeart ? microbitHeartSmall : microbitHeart;
            const nextDelay = nextImage === microbitHeart ? 500 : 120; // 120 due to forever loop delay
            timeoutRef.current = setTimeout(swapImage, nextDelay);
            return nextImage;
          });
        };
        swapImage();
        break;
      case "zero":
        setMicrobitImage(microbitZero);
        break;
      case "one":
        setMicrobitImage(microbitOne);
        break;
      case "two":
        setMicrobitImage(microbitTwo);
        break;
      default:
        throw new Error("Not implemented");
    }
  }, []);
  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  const microbitImageInnerContainerRef = useRef<HTMLImageElement>(null);
  const microbitImageOuterContainerRef = useRef<HTMLDivElement>(null);
  const { scrollYProgress } = useScroll({
    target: microbitImageOuterContainerRef,
    offset: ["start end", "end start"],
    axis: "y"
  });

  // Feature list section ref and scroll progress.
  const featureListRef = useRef<HTMLDivElement>(null);
  const { scrollYProgress: featureListEndScrollYProgress } = useScroll({
    target: featureListRef,
    offset: ["start end", "start center"],
    axis: "y"
  });

  // Add code section ref and scroll progress.
  const addCodeSectionRef = useRef<HTMLDivElement>(null);
  const { scrollYProgress: addCodeScrollYProgress } = useScroll({
    target: addCodeSectionRef,
    offset: ["start start", "end end"],
    axis: "y"
  });
  const { scrollYProgress: addCodeStartScrollYProgress } = useScroll({
    target: addCodeSectionRef,
    offset: ["start center", "start start"],
    axis: "y"
  });
  const { scrollYProgress: addCodeEndScrollYProgress } = useScroll({
    target: addCodeSectionRef,
    offset: ["end end", "end 1px"],
    axis: "y"
  });

  // Change code section ref and scroll progress.
  const changeCodeSectionRef = useRef<HTMLDivElement>(null);
  const { scrollYProgress: changeCodeScrollYProgress } = useScroll({
    target: changeCodeSectionRef,
    offset: ["start start", "end end"],
    axis: "y"
  });
  const { scrollYProgress: changeCodeEndScrollYProgress } = useScroll({
    target: changeCodeSectionRef,
    offset: ["end end", "end 1px"],
    axis: "y"
  });

  // Step into section ref and scroll progress.
  const stepIntoSectionRef = useRef<HTMLDivElement>(null);
  const { scrollYProgress: stepIntoSectionScrollYProgress } = useScroll({
    target: stepIntoSectionRef,
    offset: ["start start", "end end"],
    axis: "y"
  });
  const { scrollYProgress: stepIntoSectionEndScrollYProgress } = useScroll({
    target: stepIntoSectionRef,
    offset: ["end end", "end 1px"],
    axis: "y"
  });
  const { scrollYProgress: stepIntoSectionStartScrollYProgress } = useScroll({
    target: stepIntoSectionRef,
    offset: ["start end", "start start"],
    axis: "y"
  });

  // Step into section ref and scroll progress.
  const projectsSectionRef = useRef<HTMLDivElement>(null);
  const { scrollYProgress: projectsSectionScrollYProgress } = useScroll({
    target: projectsSectionRef,
    offset: ["start start", "end end"],
    axis: "y"
  });

  const [mobileAnimation, setMobileAnimation] = useState(false);
  useEffect(() => {
    setMobileAnimation(window.innerWidth < 992);
  }, []);
  const [microbitPadding, setMicrobitPadding] = useState(
    mobileAnimation ? "0.5rem" : "2rem"
  );
  useEffect(() => {
    const listener = (e: UIEvent) => {
      if (((e.target as Window).innerWidth || 0) < 992) {
        setMobileAnimation(true);
        setMicrobitPadding("0.5rem");
      } else {
        setMobileAnimation(false);
        setMicrobitPadding("2rem");
      }
    };
    if (typeof window !== "undefined") {
      window.addEventListener("resize", listener);
    }
    return () => window.removeEventListener("resize", listener);
  }, []);

  const [microbitWidth, setMicrobitWidth] = useState(0);
  useEffect(() => {
    if (microbitImageInnerContainerRef.current) {
      let current = microbitImageInnerContainerRef.current;
      const observer = new ResizeObserver(_ => {
        setMicrobitWidth(microbitImageInnerContainerRef.current!.clientWidth);
      });
      observer.observe(current);
      return () => current && observer.unobserve(current);
    }
  }, [microbitImageInnerContainerRef]);

  const midToLeft = useTransform(
    featureListEndScrollYProgress,
    [0, 1],
    [
      "calc(0vw - 0px - 0rem)",
      `calc(-50vw + ${(microbitWidth * 1.2) / 2}px + ${microbitPadding})`
    ]
  );
  const leftToRight = useTransform(
    addCodeStartScrollYProgress,
    [0, 1],
    [
      "calc(0vw - 0px - 0rem)",
      `calc(100vw - ${microbitWidth * 1.3}px - ${microbitPadding})`
    ]
  );
  const rightToMid = useTransform(
    changeCodeEndScrollYProgress,
    [0, 1],
    [
      "calc(0vw + 0px - 0rem)",
      `calc(-50vw + ${(microbitWidth * 1.3) / 2}px + ${microbitPadding})`
    ]
  );

  const shouldReduceMotion = useReducedMotion();

  return (
    <SectionWrapper id="computing-made-physical">
      <Helmet>
        <link rel="preload" as="image" href={microbitZero} />
        <link rel="preload" as="image" href={microbitOne} />
        <link rel="preload" as="image" href={microbitTwo} />
        <link rel="preload" as="image" href={microbitHeart} />
        <link rel="preload" as="image" href={microbitHeartSmall} />
      </Helmet>
      <motion.div
        className={styles.parallaxSection}
        style={{
          backgroundColor: useTransform(
            changeCodeEndScrollYProgress,
            [0, 1],
            ["#6C4BC1", "#7BCDC2"]
          )
        }}
      >
        <AnimatedTitle content={content} />
        <div className={styles.verticalSpacer} />
        <FeatureListSection
          ref={featureListRef}
          mobileAnimation={mobileAnimation}
          content={content}
        />
        <AddCodeSection
          ref={addCodeSectionRef}
          onChangeMicrobitImage={handleMicrobitImage}
          scrollYProgress={addCodeScrollYProgress}
          endScrollYProgress={addCodeEndScrollYProgress}
          content={content}
        />
        <ChangeCodeSection
          ref={changeCodeSectionRef}
          onChangeMicrobitImage={handleMicrobitImage}
          scrollYProgress={changeCodeScrollYProgress}
          endScrollYProgress={changeCodeEndScrollYProgress}
          content={content}
        />
        <StepIntoSection
          ref={stepIntoSectionRef}
          onChangeMicrobitImage={handleMicrobitImage}
          scrollYProgress={stepIntoSectionScrollYProgress}
          endScrollYProgress={stepIntoSectionEndScrollYProgress}
          content={content}
        />
        <ProjectsSection
          ref={projectsSectionRef}
          scrollYProgress={projectsSectionScrollYProgress}
          content={content}
        />
        <div className={styles.verticalSpacerSmall} />
        <div className={styles.track}>
          <div className={styles.stickyIconsMain} />
        </div>
        <div className={styles.track}>
          <div
            className={classNames(styles.sticky, styles.flexCenter)}
            ref={microbitImageOuterContainerRef}
          >
            <div className={styles.microbitImageContainer}>
              {shouldReduceMotion ? (
                <MicrobitWithLimitedAnimation
                  ref={microbitImageInnerContainerRef}
                  mobileAnimation={mobileAnimation}
                  midToLeft={midToLeft}
                  leftToRight={leftToRight}
                  rightToMid={rightToMid}
                  stepIntoSectionEndScrollYProgress={
                    stepIntoSectionEndScrollYProgress
                  }
                  stepIntoSectionStartScrollYProgress={
                    stepIntoSectionStartScrollYProgress
                  }
                  changeCodeEndScrollYProgress={changeCodeEndScrollYProgress}
                  stepIntoSectionScrollYProgress={
                    stepIntoSectionScrollYProgress
                  }
                  microbitImage={microbitImage}
                />
              ) : (
                <MicrobitWithFullAnimation
                  ref={microbitImageInnerContainerRef}
                  mobileAnimation={mobileAnimation}
                  midToLeft={midToLeft}
                  leftToRight={leftToRight}
                  rightToMid={rightToMid}
                  stepIntoSectionEndScrollYProgress={
                    stepIntoSectionEndScrollYProgress
                  }
                  changeCodeScrollYProgress={changeCodeScrollYProgress}
                  addCodeScrollYProgress={addCodeScrollYProgress}
                  stepIntoSectionStartScrollYProgress={
                    stepIntoSectionStartScrollYProgress
                  }
                  changeCodeEndScrollYProgress={changeCodeEndScrollYProgress}
                  stepIntoSectionScrollYProgress={
                    stepIntoSectionScrollYProgress
                  }
                  scrollYProgress={scrollYProgress}
                  microbitImage={microbitImage}
                />
              )}
            </div>
          </div>
        </div>
      </motion.div>
    </SectionWrapper>
  );
};

interface MicrobitWithFullAnimationProps {
  mobileAnimation: boolean;
  midToLeft: MotionValue<string>;
  leftToRight: MotionValue<string>;
  rightToMid: MotionValue<string>;
  stepIntoSectionEndScrollYProgress: MotionValue<number>;
  changeCodeScrollYProgress: MotionValue<number>;
  addCodeScrollYProgress: MotionValue<number>;
  stepIntoSectionStartScrollYProgress: MotionValue<number>;
  changeCodeEndScrollYProgress: MotionValue<number>;
  stepIntoSectionScrollYProgress: MotionValue<number>;
  scrollYProgress: MotionValue<number>;
  microbitImage: string;
}

const MicrobitWithFullAnimation = React.forwardRef(
  (
    {
      mobileAnimation,
      midToLeft,
      leftToRight,
      rightToMid,
      stepIntoSectionEndScrollYProgress,
      changeCodeScrollYProgress,
      addCodeScrollYProgress,
      stepIntoSectionStartScrollYProgress,
      stepIntoSectionScrollYProgress,
      changeCodeEndScrollYProgress,
      scrollYProgress,
      microbitImage
    }: MicrobitWithFullAnimationProps,
    ref: ForwardedRef<HTMLImageElement>
  ) => (
    <motion.div
      style={{
        x: mobileAnimation ? midToLeft : 0
      }}
    >
      <motion.div
        style={{
          x: mobileAnimation ? leftToRight : 0
        }}
      >
        <motion.div
          style={{
            x: mobileAnimation ? rightToMid : 0
          }}
        >
          <motion.div
            style={{
              y: useTransform(
                stepIntoSectionEndScrollYProgress,
                [0, 1],
                ["0vh", "-100vh"]
              ),
              x: useTransform(
                changeCodeScrollYProgress,
                [0.85, 0.9, 0.95],
                [0, 10, 0]
              )
            }}
          >
            <motion.div
              style={{
                y: useTransform(
                  stepIntoSectionStartScrollYProgress,
                  [0, 1],
                  ["0vh", "-10vh"]
                ),
                x: useTransform(
                  addCodeScrollYProgress,
                  [0.85, 0.9, 0.95],
                  [0, 10, 0]
                )
              }}
            >
              <motion.div
                style={{
                  margin: "0 auto",
                  rotate: useTransform(
                    changeCodeEndScrollYProgress,
                    [0, 1],
                    [0, -5]
                  ),
                  scale: useTransform(
                    changeCodeEndScrollYProgress,
                    [0, 1],
                    [1, 0.8]
                  ),
                  y: useTransform(
                    stepIntoSectionScrollYProgress,
                    [0.2, 0.37, 0.4, 0.6, 0.77, 0.8],
                    ["0vh", "-10vh", "0vh", "0vh", "-10vh", "0vh"]
                  )
                }}
              >
                <motion.div
                  style={{
                    rotate: useTransform(
                      changeCodeScrollYProgress,
                      [0.1, 0.3],
                      [0, 10]
                    )
                  }}
                >
                  <motion.div
                    style={{
                      rotate: useTransform(
                        addCodeScrollYProgress,
                        [0.1, 0.3],
                        [0, -15]
                      )
                    }}
                  >
                    <motion.div
                      style={{
                        position: "relative",

                        y: useTransform(
                          scrollYProgress,
                          [0, 0.5],
                          ["-60vh", "0vh"]
                        ),
                        rotate: useTransform(
                          scrollYProgress,
                          [0, 0.5],
                          [-10, 10]
                        )
                      }}
                    >
                      <img
                        ref={ref}
                        className={styles.microbitSVG}
                        src={microbit}
                        alt=""
                      />
                      {microbitImage && (
                        <img
                          className={classNames(
                            styles.microbitSVG,
                            styles.absolutePosition
                          )}
                          src={microbitImage}
                          alt=""
                        />
                      )}
                    </motion.div>
                  </motion.div>
                </motion.div>
              </motion.div>
            </motion.div>
          </motion.div>
        </motion.div>
      </motion.div>
    </motion.div>
  )
);

interface MicrobitWithLimitedAnimationProps {
  mobileAnimation: boolean;
  midToLeft: MotionValue<string>;
  leftToRight: MotionValue<string>;
  rightToMid: MotionValue<string>;
  stepIntoSectionEndScrollYProgress: MotionValue<number>;
  stepIntoSectionStartScrollYProgress: MotionValue<number>;
  changeCodeEndScrollYProgress: MotionValue<number>;
  stepIntoSectionScrollYProgress: MotionValue<number>;
  microbitImage: string;
}

const MicrobitWithLimitedAnimation = React.forwardRef(
  (
    {
      mobileAnimation,
      midToLeft,
      leftToRight,
      rightToMid,
      stepIntoSectionEndScrollYProgress,
      stepIntoSectionStartScrollYProgress,
      stepIntoSectionScrollYProgress,
      changeCodeEndScrollYProgress,
      microbitImage
    }: MicrobitWithLimitedAnimationProps,
    ref: ForwardedRef<HTMLImageElement>
  ) => (
    <motion.div
      style={{
        x: mobileAnimation ? midToLeft : 0
      }}
    >
      <motion.div
        style={{
          x: mobileAnimation ? leftToRight : 0
        }}
      >
        <motion.div
          style={{
            x: mobileAnimation ? rightToMid : 0
          }}
        >
          <motion.div
            style={{
              y: useTransform(
                stepIntoSectionEndScrollYProgress,
                [0, 1],
                ["0vh", "-100vh"]
              )
            }}
          >
            <motion.div
              style={{
                y: useTransform(
                  stepIntoSectionStartScrollYProgress,
                  [0, 1],
                  ["0vh", "-10vh"]
                )
              }}
            >
              <motion.div
                style={{
                  margin: "0 auto",
                  scale: useTransform(
                    changeCodeEndScrollYProgress,
                    [0, 1],
                    [1, 0.8]
                  ),
                  y: useTransform(
                    stepIntoSectionScrollYProgress,
                    [0.2, 0.37, 0.4, 0.6, 0.77, 0.8],
                    ["0vh", "-10vh", "0vh", "0vh", "-10vh", "0vh"]
                  )
                }}
              >
                <div
                  style={{
                    position: "relative"
                  }}
                >
                  <img
                    ref={ref}
                    className={styles.microbitSVG}
                    src={microbit}
                    alt=""
                  />
                  {microbitImage && (
                    <img
                      className={classNames(
                        styles.microbitSVG,
                        styles.absolutePosition
                      )}
                      src={microbitImage}
                      alt=""
                    />
                  )}
                </div>
              </motion.div>
            </motion.div>
          </motion.div>
        </motion.div>
      </motion.div>
    </motion.div>
  )
);

export default AnimatedSection;
