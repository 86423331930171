import React from "react";
import BlockContent from "../../components/BlockContent/BlockContent";
import Image from "../../components/Image/Image";
import { SanityStyledContent } from "../../components/StyledContent/StyledContent";
import YoutubeVideo from "../../components/YoutubeVideoBlock/YoutubeVideoBlock";
import { SanityImage, SanityYoutubeVideo } from "../../model/common";
import {
  SanityLandingPageCustomBlock,
  SanityLandingPageString,
  findArrayContent,
  findContent
} from "../../model/landing-page";
import FactCard from "./FactCard";
import SectionWrapper from "./SectionWrapper";
import Subtitle from "./Subtitle";
import styles from "./TechnologyForEveryone.module.scss";
import Title from "./Title";
import arrow from "./assets/arrow-2-blue.svg";
import squiggle from "./assets/blue-squiggle.svg";
import circle from "./assets/circle-blue.svg";
import circleGreen from "./assets/circle-green.svg";
import cog from "./assets/cog.svg";
import flashGreen from "./assets/flash-green.svg";
import surprise from "./assets/microbit-face-surprise.svg";
import semiCircle from "./assets/semi-circle-blue.svg";
import zigzag from "./assets/zigzag-1.svg";
import classNames from "classnames";
import TabCutout from "./TabCutout";
import IsDesktop from "../../components/IsDesktop/IsDesktop";
import IsMobile from "../../components/IsMobile/IsMobile";

const TechnologyForEveryone = ({
  node: { title, content }
}: {
  node: SanityLandingPageCustomBlock;
}) => {
  const [subtitle] = findContent<SanityStyledContent>("subtitle", content);
  const [factContent1, factContent2] = findArrayContent("factBox", content);
  const [image] = findContent<SanityImage>("image", content);
  const [text] = findContent<SanityStyledContent>("content", content);
  const [videoTitle] = findContent<SanityLandingPageString>(
    "videoTitle",
    content
  );
  const [videoSubtitle] = findContent<SanityLandingPageString>(
    "videoSubtitle",
    content
  );
  const [video] = findContent<SanityYoutubeVideo>("video", content);
  return (
    <SectionWrapper className={styles.root} id="technology-for-everyone">
      <Title as="h2">{title}</Title>
      <BlockContent
        content={subtitle.content}
        className={classNames(styles.content, styles.subtitle)}
      />
      <div className={styles.row}>
        <div className={styles.leftCol}>
          <div className={styles.factsRow}>
            <div className={styles.factCol1}>
              <FactCard content={factContent1} variant="smallem" separateUnit />
              <img src={semiCircle} alt="" className={styles.semiCircle} />
            </div>
            <div className={styles.factCol2}>
              <FactCard content={factContent2} />
              <IsMobile>
                <img
                  src={circle}
                  alt=""
                  width="41px"
                  className={styles.circle}
                />
              </IsMobile>
            </div>
          </div>
          <div className={classNames(styles.content, styles.desktop)}>
            <BlockContent content={text.content} className={styles.content} />
          </div>
          <IsDesktop>
            <img src={circle} alt="" width="41px" className={styles.circle} />
          </IsDesktop>
        </div>
        <div className={styles.rightCol}>
          <div className={styles.imageContainer}>
            <Image aspectRatio={824 / 1082} image={image} widths={800} />
            <TabCutout color="#F5F5F5" />
            <img src={cog} alt="" className={styles.cog} />
            <img src={zigzag} alt="" className={styles.zigzag} />
          </div>
        </div>
        <div className={classNames(styles.content, styles.tablet)}>
          <BlockContent content={text.content} className={styles.content} />
        </div>
      </div>
      <div className={styles.inspireSection}>
        <Title as="h2" variant="small">
          {videoTitle.value}
        </Title>
        <Subtitle>{videoSubtitle.value}</Subtitle>
        <div className={styles.videoContainer}>
          <YoutubeVideo node={video} className={styles.video} />
          <img width="65" src={flashGreen} alt="" className={styles.flash} />
          {/* Wrong icon, need a new export from XD */}
          <img src={squiggle} alt="" className={styles.squiggle} />
          <img src={circleGreen} alt="" className={styles.circle} />
          <img width="225" src={surprise} alt="" className={styles.surprise} />
          <img src={arrow} alt="" className={styles.arrow} />
        </div>
      </div>
    </SectionWrapper>
  );
};

export default TechnologyForEveryone;
