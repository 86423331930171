import React from "react";
import BlockContent, {
  BlockContentImageOptions,
  CustomBlockContentImageOptions
} from "../../components/BlockContent/BlockContent";
import { useSide } from "../../components/Ratio/Ratio";
import { SanityStyledContent } from "../../components/StyledContent/StyledContent";
import {
  SanityLandingPageCustomBlock,
  findContent
} from "../../model/landing-page";
import styles from "./EmpoweringEducators.module.scss";
import SectionWrapper from "./SectionWrapper";
import TabCutout from "./TabCutout";
import Title from "./Title";
import { ImageBlockImage } from "../../components/ImageBlock/ImageBlock";
import Card from "../../components/Card/Card";

const cutOutImageOptions: BlockContentImageOptions = {
  Image: props => {
    const side = useSide();
    return (
      <div className={styles.imgWrapper}>
        <ImageBlockImage {...props} />
        <TabCutout color="white" positionRight={side === "left"} />
      </div>
    );
  }
};

const EmpoweringEducators = ({
  node: { title, content }
}: {
  node: SanityLandingPageCustomBlock;
}) => {
  const [mainContent] = findContent<SanityStyledContent>("content", content);
  return (
    <SectionWrapper className={styles.root} id="empowering-educators">
      <Card className={styles.content}>
        <Title as="h2">{title}</Title>
        <CustomBlockContentImageOptions options={cutOutImageOptions}>
          <BlockContent content={mainContent.content} />
        </CustomBlockContentImageOptions>
      </Card>
    </SectionWrapper>
  );
};

export default EmpoweringEducators;
