import classNames from "classnames";
import React from "react";
import BlockContent from "../../components/BlockContent/BlockContent";
import { CustomBlockCompontent } from "../../model/landing-page";
import styles from "./FactCard.module.scss";
import { SanityStyledContent } from "../../components/StyledContent/StyledContent";
import { SanityLandingPageString } from "../../model/landing-page";
import Card from "../../components/Card/Card";

const FactCard = ({
  content,
  variant = "default",
  separateUnit = false
}: {
  content: CustomBlockCompontent[];
  variant?: "smallem" | "default";
  separateUnit?: boolean;
}) => {
  const [number, unit, info] = content;
  const stat = (
    <p>
      <strong>{(number as SanityLandingPageString).value}</strong>
      {separateUnit && " "}
      <em>{(unit as SanityLandingPageString).value}</em>
    </p>
  );
  return (
    <Card className={classNames(styles.root, styles[variant])} type="main">
      <BlockContent
        className={styles.content}
        content={(info as SanityStyledContent).content}
        before={stat}
      />
    </Card>
  );
};

export default FactCard;
