import classNames from "classnames";
import React, { ReactNode } from "react";
import styles from "./Title.module.scss";

const Title = ({
  children,
  as,
  variant = "large",
  maxWidth = "900px"
}: {
  children: ReactNode;
  as: "h1" | "h2";
  variant?: "main" | "large" | "small";
  maxWidth?: string;
}) => {
  const Component = as;
  return (
    <Component
      className={classNames(styles.root, styles[variant])}
      style={{ maxWidth }}
    >
      {children}
    </Component>
  );
};

export default Title;
