import classNames from "classnames";
import {
  motion,
  MotionValue,
  useReducedMotion,
  useScroll,
  useTransform
} from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { SanityStyledContent } from "../../../components/StyledContent/StyledContent";
import {
  findContent,
  SanityLandingPageString
} from "../../../model/landing-page";
import { Content } from "./AnimatedSection";
import styles from "./AnimatedSection.module.scss";
import ProjectCard from "./ProjectCard";

interface ProjectsSectionProps {
  scrollYProgress: MotionValue<number>;
  content: Content;
}

const ProjectsSection = React.forwardRef(
  (
    { content, scrollYProgress }: ProjectsSectionProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const [subtitle] = findContent<SanityLandingPageString>(
      "stepIntoTheRealWorldSubtitle",
      content
    );
    const stepIntoTheRealWorldCards = findContent<SanityStyledContent>(
      "stepIntoTheRealWorldCards",
      content
    );

    const { scrollYProgress: startScrollYProgress } = useScroll({
      target: ref as React.RefObject<HTMLDivElement>,
      offset: ["start end", "start start"]
    });

    const cardContainerRef = useRef<HTMLDivElement>(null);
    const fixedElRef = useRef<HTMLDivElement>(null);
    const targetPosRef = useRef<HTMLDivElement>(null);

    const [scrollWidth, setScrollWidth] = useState(0);
    useEffect(() => {
      if (cardContainerRef.current) {
        let current = cardContainerRef.current;
        const observer = new ResizeObserver(_ => {
          setScrollWidth(
            cardContainerRef.current!.scrollWidth -
              fixedElRef.current!.clientWidth
          );
        });
        observer.observe(current);
        return () => current && observer.unobserve(current);
      }
    }, [cardContainerRef]);

    const x = useTransform(
      scrollYProgress,
      [0, 1],
      ["0px", `-${scrollWidth}px`]
    );

    const shouldReduceMotion = useReducedMotion();

    return (
      <section
        className={classNames(styles.animatedSection, styles.projectsSection)}
        ref={ref}
      >
        <div
          className={classNames(
            !shouldReduceMotion && styles.fixedPosition,
            styles.maxWidthCentered,
            styles.stickyPosition
          )}
          ref={fixedElRef}
        >
          <motion.div
            className={classNames(styles.restrictedContent)}
            style={{
              position: "absolute",
              top: useTransform(
                startScrollYProgress,
                [0, 1],
                [
                  0,
                  (targetPosRef.current?.getBoundingClientRect().top || 0) -
                    (fixedElRef.current?.getBoundingClientRect().top || 0)
                ]
              )
            }}
          >
            <p className={classNames(styles.text, styles.noPadding)}>
              {subtitle.value}
            </p>
          </motion.div>
          <div className={styles.verticalSpacerSmall} ref={targetPosRef} />
          <motion.div
            ref={cardContainerRef}
            className={styles.projectCardsContainer}
            style={{
              x: shouldReduceMotion ? 0 : x
            }}
          >
            {stepIntoTheRealWorldCards.map((styledContent, i) => {
              return (
                <ProjectCard
                  key={i}
                  image={styledContent.content[0]}
                  content={[styledContent.content[1]]}
                  even={i % 2 === 0}
                />
              );
            })}
          </motion.div>
        </div>
      </section>
    );
  }
);
export default ProjectsSection;
