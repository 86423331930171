import { motion, useReducedMotion } from "framer-motion";
import React from "react";
import BlockContent from "../../../components/BlockContent/BlockContent";
import { SanityImage, SanityRawContent } from "../../../model/common";
import { imageUrlBuilder } from "../../../utils/images";
import TabCutout from "../TabCutout";
import styles from "./AnimatedSection.module.scss";

interface ProjectCardProps {
  image: SanityImage;
  content: SanityRawContent;
  even: boolean;
}

const ProjectCard = ({ image, content, even }: ProjectCardProps) => {
  const shouldReduceMotion = useReducedMotion();
  return (
    <motion.div
      className={styles.projectCard}
      style={{
        rotate: even ? -2 : 4,
        y: shouldReduceMotion ? 0 : even ? 0 : 50,
        zIndex: even ? 0 : 1
      }}
    >
      <div className={styles.imageContainer}>
        <img
          className={styles.image}
          alt={image.alt}
          src={
            imageUrlBuilder
              .image(image)
              .width(553)
              .height(400)
              .fit("fill")
              .url()!
          }
        />
        <TabCutout color="#7BCDC2" positionRight={!even} />
      </div>
      <BlockContent content={content} />
    </motion.div>
  );
};

export default ProjectCard;
