import { WindowLocation } from "@reach/router";
import { graphql } from "gatsby";
import React from "react";
import LandingPageContent from "../components/LandingPageContent/LandingPageContent";
import PageLayout from "../layouts/PageLayout/PageLayout";
import { CommonContext, SanityPage } from "../model/common";
import {
  LandingPageItem,
  SanityLandingPageCustomBlock
} from "../model/landing-page";
import { GlobalMenus } from "../model/menu";
import { SiteArea } from "../utils/analytics";
import AnimatedSection from "./WhatIsMicrobit/AnimatedSection/AnimatedSection";
import EasyEffectiveEngaging from "./WhatIsMicrobit/EasyEffectiveEngaging";
import EmpoweringEducators from "./WhatIsMicrobit/EmpoweringEducators";
import FAQ from "./WhatIsMicrobit/FAQ";
import GetStartedOrBuy from "./WhatIsMicrobit/GetStartedOrBuy";
import Hero from "./WhatIsMicrobit/Hero";
import OnlineCodingTools from "./WhatIsMicrobit/OnlineCodingTools";
import TechnologyForEveryone from "./WhatIsMicrobit/TechnologyForEveryone";

export const pageQuery = graphql`
  query LandingPage($_id: String!, $navLanguage: String) {
    menus: sanityGlobalConfig(language: { eq: $navLanguage }) {
      ...MenuData
    }
    page: sanityLandingPage(_id: { eq: $_id }) {
      _id
      _type
      language
      title
      metaDescription
      socialTitle
      socialDescription
      socialImage {
        ...SanityImage
      }
      _rawContent(resolveReferences: { maxDepth: 16 })
    }
  }
`;

const LandingPage = ({
  pageContext,
  data: { menus, page },
  location
}: LandingPageProps) => {
  const { title } = page;
  const renderCustom = (item: SanityLandingPageCustomBlock) => {
    switch (item.type) {
      case "hero": {
        return <Hero node={item} key={item._key} />;
      }
      case "easyEffectiveEngaging": {
        return <EasyEffectiveEngaging node={item} key={item._key} />;
      }
      case "animatedSection": {
        return <AnimatedSection node={item} key={item._key} />;
      }
      case "technologyDesignedForEveryone": {
        return <TechnologyForEveryone node={item} key={item._key} />;
      }
      case "empoweringEducators": {
        return <EmpoweringEducators node={item} key={item._key} />;
      }
      case "onlineCodingTools": {
        return <OnlineCodingTools node={item} key={item._key} />;
      }
      case "getStartedOrBuy": {
        return <GetStartedOrBuy node={item} key={item._key} />;
      }
      case "faq": {
        return <FAQ node={item} key={item._key} />;
      }
      default: {
        return null;
      }
    }
  };
  return (
    <PageLayout
      siteArea={SiteArea.UNKNOWN}
      metadata={{
        title,
        page,
        alternates: pageContext.alternates
      }}
      menus={menus}
      location={location}
      strings={pageContext.strings}
    >
      <LandingPageContent
        content={page._rawContent}
        title={page.title}
        renderCustom={renderCustom}
      />
    </PageLayout>
  );
};

export interface SanityLandingPage extends SanityPage {
  layout?: "default" | string;
  _rawContent: LandingPageItem[];
}

interface LandingPageProps {
  data: {
    menus: GlobalMenus;
    page: SanityLandingPage;
  };
  location: WindowLocation;
  pageContext: CommonContext;
}

export default LandingPage;
