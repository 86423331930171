import React from "react";
import styles from "./AnimatedDescription.module.scss";

interface AnimationDescriptionProps {
  id: string;
  text: string;
  show: boolean;
}

const AnimationDescription = ({
  id,
  text,
  show
}: AnimationDescriptionProps) => (
  <div id={id} aria-live="polite" className={styles.root}>
    <div
      style={{
        display: show ? "block" : "none"
      }}
    >
      <p>{text}</p>
    </div>
  </div>
);

export default AnimationDescription;
