import { Wrapper } from "../../components/Grid";
import React, { ReactNode } from "react";

const SectionWrapper = ({
  style,
  className,
  children,
  id
}: {
  style?: React.CSSProperties;
  children: ReactNode;
  className?: string;
  id?: string;
}) => {
  return (
    <Wrapper
      style={{
        maxWidth: "1336px",
        padding: 0,
        margin: "0 auto"
      }}
    >
      <section
        id={id}
        className={className}
        style={{
          ...style
        }}
      >
        {children}
      </section>
    </Wrapper>
  );
};

export default SectionWrapper;
