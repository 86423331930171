import React from "react";
import {
  findContent,
  SanityLandingPageCustomBlock
} from "../../model/landing-page";

import classNames from "classnames";
import BlockContent from "../../components/BlockContent/BlockContent";
import { SanityStyledContent } from "../../components/StyledContent/StyledContent";
import squiggle from "./assets/blue-squiggle.svg";
import circle from "./assets/circle-green.svg";
import compass from "./assets/compass-green.svg";
import hand from "./assets/hand-green.svg";
import leds from "./assets/leds-blue.svg";
import microphone from "./assets/mic-blue.svg";
import phone from "./assets/phone-blue.svg";
import semiCircle from "./assets/semi-circle-green.svg";
import swirl from "./assets/swirl-green.svg";
import styles from "./GetStartedOrBuy.module.scss";
import SectionWrapper from "./SectionWrapper";
import TabCutout from "./TabCutout";
import Card from "../../components/Card/Card";

const GetStartedOrBuy = ({
  node: { content }
}: {
  node: SanityLandingPageCustomBlock;
}) => {
  const choices = findContent<SanityStyledContent>("choices", content);

  const greenImage = (
    <div className={styles.imageContainer}>
      <img
        className={classNames(styles.icon, styles.swirl)}
        src={swirl}
        alt=""
      />
      <img
        className={classNames(styles.icon, styles.compass)}
        src={compass}
        alt=""
      />
      <img className={classNames(styles.icon, styles.hand)} src={hand} alt="" />
      <div className={classNames(styles.image, styles.imageGreen)}>
        <TabCutout color="#fff" />
        <img
          className={classNames(styles.icon, styles.filteredIcon, styles.swirl)}
          src={swirl}
          alt=""
        />
        <img
          className={classNames(
            styles.icon,
            styles.filteredIcon,
            styles.compass
          )}
          src={compass}
          alt=""
        />
        <img
          className={classNames(
            styles.icon,
            styles.filteredIcon,
            styles.circle
          )}
          src={circle}
          alt=""
        />
        <img
          className={classNames(
            styles.icon,
            styles.filteredIcon,
            styles.semiCircle
          )}
          src={semiCircle}
          alt=""
        />
        <img
          className={classNames(styles.icon, styles.filteredIcon, styles.hand)}
          src={hand}
          alt=""
        />
      </div>
    </div>
  );

  const blueImage = (
    <div className={styles.imageContainer}>
      <img
        className={classNames(styles.icon, styles.microphone)}
        src={microphone}
        alt=""
      />
      <img
        className={classNames(styles.icon, styles.squiggle)}
        src={squiggle}
        alt=""
      />
      <img
        className={classNames(styles.icon, styles.phone)}
        src={phone}
        alt=""
      />
      <img className={classNames(styles.icon, styles.leds)} src={leds} alt="" />
      <div className={classNames(styles.image, styles.imageBlue)}>
        <TabCutout color="#fff" />
        <img
          className={classNames(
            styles.icon,
            styles.filteredIcon,
            styles.microphone
          )}
          src={microphone}
          alt=""
        />
        <img
          className={classNames(
            styles.icon,
            styles.filteredIcon,
            styles.squiggle
          )}
          src={squiggle}
          alt=""
        />
        <img
          className={classNames(styles.icon, styles.filteredIcon, styles.phone)}
          src={phone}
          alt=""
        />
        <img
          className={classNames(styles.icon, styles.filteredIcon, styles.leds)}
          src={leds}
          alt=""
        />
      </div>
    </div>
  );
  return (
    <SectionWrapper className={styles.root}>
      <div className={styles.cards}>
        {choices.map((item, i) => (
          <Card className={styles.card} key={item._key}>
            {i === 0 ? greenImage : blueImage}
            <BlockContent content={item.content} />
          </Card>
        ))}
      </div>
    </SectionWrapper>
  );
};

export default GetStartedOrBuy;
